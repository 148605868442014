<template>
    <Page>
        <template v-slot:title>
            Редактировать данные
        </template>
        <div class="edit-wrapper">
            <FormTemplate
                action="Обновить"
                @submit="submit"
                class="edit-page"
            >
                <fieldset>
                    <Field
                        v-model.trim="form.lastname"
                        :error="formErrors.lastname"
                        title="Фамилия"
                        placeholder="Иванов"
                    />
                    <Field
                        v-model.trim="form.firstname"
                        :error="formErrors.firstname"
                        title="Имя"
                        placeholder="Иван"
                    />
                </fieldset>
                <fieldset>
                    <Field
                        v-model.trim="form.patronymic"
                        :error="formErrors.patronymic"
                        title="Отчество"
                        subtitle="(если есть)"
                        placeholder="Иванович"
                    />
                    <Field
                        v-model.trim="form.email"
                        :error="formErrors.email"
                        title="E-mail"
                        placeholder="user@mail.ru"
                    />
                </fieldset>
                <fieldset>
                    <Date
                        v-model="form.birthday"
                        :error="formErrors.birthday"
                        title="Дата рождения"
                    />
                    <ZSwitch
                        v-model="form.gender"
                        :error="formErrors.gender"
                        title="Пол"
                        :list="[{
                            key: '0',
                            value: 'М'
                        }, {
                            key: '1',
                            value: 'Ж'
                        }]"
                    />
                </fieldset>
                <fieldset>
                    <Address
                        v-model.trim="form.addrcity"
                        :error="formErrors.addrcity"
                        title="Адрес регистрации"
                    />
                </fieldset>
                <fieldset>
                    <fieldset>
                        <Field
                            v-model="form.passportseries"
                            :error="formErrors.passportseries"
                            title="Серия паспорта"
                            mask="## ##"
                            valueWithoutMask
                            placeholder="22 20"
                        />
                        <Field
                            v-model="form.passportnumber"
                            :error="formErrors.passportnumber"
                            title="Номер"
                            mask="### ###"
                            valueWithoutMask
                            placeholder="123 456"
                        />
                    </fieldset>
                    <fieldset>
                        <Field
                            v-model="form.passportissuecode"
                            :error="formErrors.passportissuecode"
                            title="Код подразделения"
                            mask="###-###"
                            valueWithoutMask
                            placeholder="000-000"
                        />
                        <Date
                            title="Дата выдачи"
                            v-model="form.passportissuedate"
                            :error="formErrors.passportissuedate"
                        />
                    </fieldset>
                </fieldset>
            </FormTemplate>
        </div>
    </Page>
</template>

<script>
import Date from '@/components/form/field/Date'
import Address from '@/components/form/search/Address'
import ZSwitch from '@/components/form/switch/Switch'

import Page from '@/applications/personal-area/layouts/page/Page'

import FormTemplate from '@/templates/form/Form'

import { mapGetters } from 'vuex'
import validation from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'
import { NAME_RULES, MAX_BORROWER_YEAR, MIN_BORROWER_YEAR, MAX_BORROWER_OLD, MIN_BORROWER_OLD, NAME_ERROR_MSG } from '@/const/validationValues'

import changeData from '@/api/changeData'
import setMask from '@/helpers/string/setMask'

import { year, month, day } from '@/helpers/date/currentDate'
const TODAY = `${day}.${month}.${year}`

const ISSUE_DATE_VALIDATE = [Validation.DATE, [Validation.DATE_LESS, TODAY]]

import './edit-page.scss'

export default {
    name: 'EditProfile',
    mixins: [
        validation
    ],
    created() {
        this.form.firstname = this.data.name
        this.form.lastname = this.data.lastname
        this.form.patronymic = this.data.patronymic
        this.form.email = this.data.email || ''
        this.form.birthday = this.data.birthday
        this.form.gender = String(this.user.contactData.gender)
        this.form.addrcity = this.data.city

        this.form.passportseries = this.user.passportData.passportseries
        this.form.passportnumber = this.user.passportData.passportnumber
        this.form.passportissuecode = this.user.passportData.passportissuecode
        this.form.passportissuedate = this.user.passportData.passportissuedate
    },
    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                patronymic: '',
                email: '',
                birthday: '',
                gender: '',
                addrcity: '',
                passportseries: '',
                passportnumber: '',
                passportissuecode: '',
                passportissuedate: ''
            },
            formRules: {
                firstname: [
                    Validation.REQUIRED,
                    ...NAME_RULES,
                ],
                lastname: [
                    Validation.REQUIRED,
                    ...NAME_RULES,
                ],
                patronymic: [
                    ...NAME_RULES,
                ],
                email: [Validation.EMAIL],
                birthday: [Validation.REQUIRED, Validation.DATE, [Validation.DATE_LESS, MIN_BORROWER_YEAR], [Validation.DATE_MORE, MAX_BORROWER_YEAR]],
                gender: [Validation.REQUIRED],
                addrcity: [Validation.REQUIRED],
                passportissuecode: [
                    Validation.REQUIRED,
                    [Validation.MIN, 6],
                    [Validation.MAX, 6]
                ],
                passportnumber: [
                    Validation.REQUIRED,
                    [Validation.MIN, 6],
                    [Validation.MAX, 6]
                ],
                passportseries: [
                    Validation.REQUIRED,
                    [Validation.MIN, 4],
                    [Validation.MAX, 4]
                ],
                passportissuedate: ISSUE_DATE_VALIDATE
            },
            customErrors: {
                firstname: NAME_ERROR_MSG,
                lastname: NAME_ERROR_MSG,
                patronymic: NAME_ERROR_MSG,
                birthday: {
                    [Validation.DATE_LESS]: `Мы не выдаем займ лицам не достигшим ${MIN_BORROWER_OLD} лет`,
                    [Validation.DATE_MORE]: `Возраст заемщика не должен превышать ${MAX_BORROWER_OLD} лет`,
                },
                passportissuedate: {
                    [Validation.DATE_LESS]: 'Дата не может быть больше текущей',
                },
            }
        }
    },
    computed: {
        ...mapGetters({
            data: 'personal/data',
            user: 'application/user'
        })
    },
    methods: {
        async submit() {
            this.validate()

            if (!this.isValid)
                return

            const data = {
                ...this.form,
                passportissuecode: setMask(this.form.passportissuecode, '###-###'),
                phone: this.user.contactData.phone
            }

            await changeData(data)

            this.$router.push({
                name: 'SaveChanges',
                params: {
                    data
                }
            })
        }
    },
    components: {
        FormTemplate,
        Date,
        ZSwitch,
        Address,
        Page
    }
}
</script>